::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(168, 168, 168, 1);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(168, 168, 168, 1);
    background-color: #A8A8A8;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: #F5F9FF !important;
    color: #0054A6 !important;
}

.ant-notification-notice-success {
    background: #F2FFF6 !important;
    border: 1px solid #66CC81;

    .ant-notification-notice-description,
    .ant-notification-notice-message{
        color: #66cc81 !important;
    }
}

.ant-notification-notice-error {
    border: 1px solid #D91636;

    .ant-notification-notice-description,
    .ant-notification-notice-message{
        color: #D91636 !important;
    }
}

.ant-form-item-has-error {
    input {
        border-color: #ff4d4f !important;

        &:focus {
            box-shadow: none !important;
        }
    }
}

.ant-select-tree-node-content-wrapper-normal {
    span {
        color: #006CD9 !important;
    }
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: #E6F2FF !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ff4d4f !important;
    box-shadow: none !important;
}

.ant-notification {
    right: 30px !important;
}

.ant-notification-notice {
    max-width: max-content !important;
}

.ant-select-dropdown {
    border-radius: 6px !important;
}

.popover-certificate {
    .ant-popover-inner {
        border-radius: 10px !important;
    }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #006CD9 !important;
    color: #fff !important;
}

.select2-container--default .select2-results__option--selected {
    background-color: #e6f2ff !important;
    color: #006cd9 !important;
}

.dropdown-item {
    &:first-child {
        &:hover, &:focus {
            border-radius: 10px 10px 0 0;
        }
    }

    &:last-child {
        &:hover, &:focus {
            border-radius: 0 0 10px 10px;
        }
    }
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    background: #21252994;
    z-index: 999;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.detail-order {
    .search-box {
        .ant-form-item.input-box {
            float: left;
            width: 85%;

            input {
                height: 40px;
                border-radius: 5px 0 0 5px;
            }
        }

        .ant-btn {
            border: none;
            border-radius: 0 5px 5px 0;
            float: left;
            width: 15%;
            height: 40px;
        }

        .ant-btn, .order .ant-btn:active, .order .ant-btn:hover {
            background: #006cd9;
        }
    }

    .select-box {
        .ant-select-selector {
            border-radius: 5px !important;
            height: 40px !important;
            padding: 4px 11px !important;
        }
    }

    .header {
        height: 60px;
        line-height: 60px;
        background-color: #f2f5fa;
        border: 1px solid rgba(0, 0, 0, .125);

        a {
            padding-left: 25px;
            padding-right: 25px;
            color: #000 !important;
            font-size: 15px;
        }

        a.active {
            position: relative;

            &:after {
                top: 37px;
                position: absolute;
                display: block;
                width: 100px;
                height: 2px;
                background: #006cd9;
                left: 0;
                right: 0;
                margin: auto;
                content: "";
            }
        }
    }

    .ant-card {
        margin-bottom: 30px !important;
        border: 1px solid #ccc !important;
        border-right: 6px;

        .ant-card-head {
            background-color: rgba(0, 0, 0, .03);
            border-bottom: 1px solid rgba(0, 0, 0, .125);

            .ant-card-head-title {
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        .ant-form-item {
            width: 90%;
            float: left;

            input {
                height: 50px !important;
                border-top-left-radius: 6px !important;
                border-bottom-left-radius: 6px !important;
            }
        }

        .btn-submit {
            width: 10%;
            height: 50px;
            background: #006cd9;
            color: #fff;
            border: none;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }

        p {
            word-break: break-all;
        }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 10px !important;
        font-size: 13px;
    }

    .file-item {
        margin-bottom: 15px;

        .file-icon {
            margin-right: 15px;
        }
    }

    .time-cmt {
        font-size: 11px;
        margin-left: 30px;
        font-weight: 400;
        color: #6a6a6a;
    }

    .cs-image {
        border-radius: 100%;
        object-fit: cover;
    }

    .note-info {
        width: 95%;
    }

    .btn-delete-note {
        font-size: 30px;
        height: 50px;
        line-height: 50px;
        border-radius: 100%;
        text-align: center;
        width: 50px;
        border: 1px solid #ececec;
        color: #006cd9;
        cursor: pointer;
    }
}

.select2-results__option--highlighted {
    background: #E6F2FF !important;
    color: #006CD9 !important;
}

.select2-results__options {
    border-radius: 5px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-radius: 5px;
}

.select2-results__option {
    color: #6A6A6A;
    background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    height: 35px !important;
}

.ant-form-item {
    margin-bottom: 15px !important;
}

.row-danger {
    background-color: #f8d7da;
}

.form-control:focus, #name_contains:focus, .input-search:focus {
    border-color: #80BFFF !important;
    box-shadow: none !important;
}

.font-italic {
    font-style: italic;
}

.f-14 {
    font-size: 14px;
}

.f-12 {
    font-size: 12px;
}

.f-11 {
    font-size: 11px;
}

.f-10 {
    font-size: 10px;
}

.f-8 {
    font-size: 8px;
}

.color-white {
    color: $colorWhiteDefault;
}

.font-samiBold {
    font-weight: 600;
    font-family: $fontFamilySemiBold;
}

.font-bold {
    font-weight: 700;
    font-family: $fontFamilyBold;
}

.font-normal {
    font-family: $fontFamily;
}

.color-gray {
    color: $colorTextDefault;
}

.color-orange {
    color: $colorOrangeDefault;
}

.color-black-default {
    color: #00030E;
}

.p-flex-home-100 {
    padding-bottom: 100px;
    padding-top: 100px;
}

.is-invalid {
    background-color: #FFF2F4 !important;
    //background-image: url("../images/v2/error-form.png") !important;
    background-image: unset !important;
    border: 1px solid #FF8095 !important;
    color: #EB5757 !important;
    background-size: unset !important;
}

.is-invalid.hide-icon {
    right: 40px;
    bottom: 52px;
}

.invalid-feedback {
    margin-top: 0 !important;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
}

.infinite-scroll-component {
    overflow: unset !important;
}

.limit-line-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    visibility: visible;
}

.limit-line-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    visibility: visible;
}

.limit-line-3 {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    visibility: visible;
}

.font-bold {
    font-family: $fontFamilyBold;
}

.ant-notification-notice {
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    border-radius: 10px !important;
    padding: 13px 24px !important;

    .ant-notification-notice-message {
        margin-bottom: 0 !important;
    }

    .ant-notification-notice-close-x {
        display: none !important;
    }
}

.ant-card-body {
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .detail-order .btn-delete-note {
        font-size: 20px;
        height: 35px;
        line-height: 35px;
        width: 35px;
    }
}

.alertify-notifier.ajs-top.ajs-right {
    .ajs-message.ajs-success.ajs-visible {
        background: #F2FFF6 !important;
        border: 1px solid #66CC81 !important;
        box-sizing: border-box !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2) !important;
        border-radius: 10px !important;
        width: 305px !important;
        font-family: $fontFamily !important;
    }
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 325px !important;
}

.ant-checkbox-wrapper-checked {
    span:nth-child(2) {
        color: #0054A6;
    }
}

.tree-multiple {
    .ant-select-selector {
        //padding-left: 10px !important;
    }

    .ant-select-selection-item {
        background: #f5f9ff !important;
        border: 1px solid #80bfff !important;
        border-radius: 20px !important;
        box-sizing: border-box !important;
        margin-left: 5px !important;

        .ant-select-selection-item-content {
            padding-left: 8px !important;
            font-weight: 600;
            //max-width: 100px;
        }

        .ant-select-selection-item-remove {
            padding-right: 4px !important;
            margin-top: -2px !important;

            svg {
                width: 9px;
                color: #6A6A6A;
                height: 9px;
                margin-top: 1px;
            }
        }
    }
}

.tree-multiple-dropdown {
    .tree-multiple {
        .ant-select-selector:focus {
            border: 1px solid #c1c1c1 !important;
        }
    }

    font-family: $fontFamily;
    border: 1px solid #80BFFF !important;

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: unset !important;
        border: 1px solid #80BFFF !important;
    }


    .ant-select-tree-switcher {
        width: 15px;
    }

    .ant-select-tree-title {
        color: $colorTextDefault;
    }

    .ant-select-tree-treenode-checkbox-checked {
        .ant-select-tree-title {
            color: $bgBtnDefault;

            &:hover {
                color: #006cd9;
            }
        }
    }
}

.portfolio-contractor-info {
    .ant-input-number-handler-wrap {
        display: none;
    }

    input {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        padding: 6px;
    }

    .amount-number {
        input {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: unset;
        }

        .ant-input-number-group-addon {
            background-color: unset;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            color: #A8A8A8;
            border: 1px solid $borderDefault;
            border-left: unset;
        }
    }
}

.year-complete {
    .ant-form-item .ant-picker input {
        border: none !important;
        height: 35px !important;
    }

    .ant-form-item .ant-picker input {
        //border: none !important;
        height: 32px !important;
        line-height: 32px !important;
    }

    .ant-picker-suffix {
        display: none;
    }

    .ant-picker-clear {
        right: 5px;
    }

    .ant-picker-input {
        border: 1px solid #C1C1C1;
        border-radius: 5px;
    }
}

.form-autocomplete {
    .ant-select-selector {
        height: 50px !important;
    }
}

//.ant-select-selection-overflow-item {
//    align-self: unset !important;
//}

button:focus, input:focus, .btn:focus, .btn.focus,
button:active, input:active, .btn:active, .btn.active {
    box-shadow: unset !important;
    border-color: #c1c1c1 !important;
}

.btn-primary {
    border-color: unset !important;
}

.tree-multiple-dropdown {
    .tree-multiple {
        .ant-select, .ant-select-selector {
            height: auto !important;
            line-height: unset !important;
        }

        .ant-select-selector {
            min-height: 45px !important;
        }

        .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
            height: 29px !important;
        }

        .ant-select-selection-search-input {
            height: 35px !important;
        }
    }
}

.ant-select-dropdown.tree-multiple-dropdown.ant-tree-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border: 1px solid #80BFFF;
    background: #FAFCFF;
}

.acreage-input {
    .ant-input-number-group-wrapper {
        width: 100%;
    }

    .ant-input-number-group-addon {
        border-radius: 5px;
        border: 1px solid #C1C1C1;
        background: #E6E6E6;
    }

    .ant-input-number-handler-wrap {
        display: none;
    }

    .ant-input-number-disabled {
        input {
            background: #e6e6e6 !important;
            color: #6a6a6a
        }
    }
}

@media screen and (max-width: 1024px) {
    .portfolio-contractor-info{
        label{
            width: 50%;
        }
        .ant-input-number, .ant-input-number, .ant-input-number-wrapper{
            width: 100%;
            input{
                width: 100%;
            }
        }
    }
    #contractor .ant-tabs, #designer-account-root .ant-tabs, #customer .ant-tabs {
        display: block;
        height: auto;
    }
    #contractor .ant-tabs .ant-tabs-nav-list, #designer-account-root .ant-tabs .ant-tabs-nav-list, #customer .ant-tabs .ant-tabs-nav-list {
        width: 100% !important;
    }
}

@media screen and (max-width: 767px) {
    .detail-order {
        .header {
            a {
                font-size: 11px !important;
                padding-left: 5px !important;
                padding-right: 5px !important;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #modal-booking {
        .container {
            max-width: 100% !important;
        }
    }
}

.scroll-disabled {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}
