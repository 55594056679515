@import "../../vendors/alertifyjs/build/css/alertify";

#footer {
    font-family: $fontFamily;
    background-color: #0054a6;
    font-size: 14px;
    color: #333;
    h3{
        font-family: $fontFamilySemiBold;
    }
}
#footer .logo-footer {
    max-width: 55%;
}
.text-light {
    color: #f8f9fa!important;
}
.list-inline, .list-unstyled {
    list-style: none;
    padding-left: 0;
}
.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}
#footer .copyright-flex {
    background: #02498e;
    color: #fff;
    opacity: .6;
    padding: 20px;
    text-align: center;
}

#modal-map {
    .modal-header {
        button {
            height: 29px !important;
            top: -1px !important;
        }
    }
}
