.contact-position-fixed-right{
    .back-to-top{
        position: fixed;
        right: -5px;
        top: 350px;
        z-index: 99;
    }
    ul{
        padding: 0;
        position: fixed;
        right: 5px;
        top: 200px;
        border-radius: 10px;
        z-index: 99;
        background: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        li{
            padding: 20px;
            list-style: none;
            text-align: center;
            position: relative;
            &:first-child{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &:last-child{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
        li:after{
            content: "";
            background: #A8A8A8;
            display: block;
            height: 1px;
            width: 60%;
            left: 0;
            margin: auto;
            right: 0;
            bottom: 0;
            z-index: 99;
            position: absolute;
        }
        li:last-child:after{
            content: none !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .contact-position-fixed-right{
        ul{
            li{
                padding: 15px;
            }
        }
    }
}
