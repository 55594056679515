//model đặt lịch
.error {
    color: red;
}

#modal-booking, #modal-booking-detail, #modal-booking-schedule-detail {
    font-family: $fontFamily !important;

    .modal-lg {
        //padding-top: 5%;
        .modal-content {
            background: #f5f5f5 !important;
            border-radius: 20px;
            border: none !important;
        }

        .img-right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .modal-header {
            border-bottom: none;
            padding: 25px 0 0 0 !important;
        }

        .modal-body {
            padding: 30px;

            input.form-control {
                height: 50px;
                background: #FDFDFD;
                border: 1px solid #C1C1C1;
                box-sizing: border-box;
                border-radius: 10px;
            }
        }

        .close {
            opacity: 1 !important;
        }
    }

    .md-nhan-vien {
        background-color: #fff;
        border-radius: 5px;
    }

    .bg-md {
        background: url("../images/v2/image-popup.jpeg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 100%;
    }
}

.text-flex-home {
    color: #f37021;
    font-weight: 700;
}

.btn-book {
    background-color: #006cd9;
    border-radius: 20px;
    font-size: 15px;
    border: none;
    margin: 0 auto 30px auto;
    padding: 8px 15px;
    display: block;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
}

#modal-thank-you {
    font-family: $fontFamily;

    .modal-lg {
        //padding-top: 5%;

        .img-right {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .modal-header {
            border-bottom: none;
            padding-left: 23px;
        }

        .modal-body {
            padding-top: 0;
            padding-left: 23px;
        }
    }

    .thanks_right {
        padding: 68px 11px;

        img {
            margin-bottom: 25px;
        }

        h3 {
            margin-bottom: 10px;
        }
    }
}


#modal-thank-you {
    .bg-md {
        background: url("../images/v2/bg-model.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 1000px !important;
    }
}

