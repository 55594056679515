.position-relative {
    position: relative !important;
}

.fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030;
    top: 0;
}

.bao-gia-nhanh {
    background: #f37021;
    padding: 0 15px !important;
    border-radius: 30px;

    a {
        padding: 10px;
        color: #fff !important;
    }

    &:after {
        content: none !important;
    }

    &:hover {
        &:after {
            content: none !important;
        }
    }
}

.dropdown-menu, .dropdown-menu-3 {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: none !important;
    padding: 0 !important;

    .dropdown-item {
        padding: 8px 15px !important;
    }
}

#header {
    .notification-header {
        .dropdown-toggle:after {
            content: unset;
        }

        .amount-notification {
            position: absolute;
            font-size: 10px;
            color: #fff;
            right: 24px;
            top: 10px;
        }
    }

    .notification-header.dropdown {
        position: unset !important;
    }

    .notification-dropdown.dropdown-menu {
        left: unset !important;
    }

    .notification-dropdown {
        right: 0 !important;
        width: 301px;
        border: unset;
        border-radius: unset;
    }

    .notification-dropdown-list {
        overflow-y: scroll;
        min-height: 35vh;
        max-height: 65vh;
    }

    .notification-dropdown .btn-load-more {
        background-color: unset;
        color: #de7538;
        font-size: 15px;
    }

    .notification-dropdown-title {
        background-color: #F6F6F6;
        text-transform: uppercase;
    }

    .notify-title-time {
        font-size: 10px;
        opacity: 0.6;
    }

    .notify-title-name {
        font-size: 13px;
    }

    .dropdown-item.notify-item {
        cursor: pointer !important;
    }

    .dropdown-item.notify-item:hover {
        transition: 0.4s;
        background: #ECF6FF !important;
        border: 1px solid #0054A6 !important;
    }

    .notify-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

    }

    .notify-desc-content {
        font-size: 12px;
        white-space: normal;
    }


    padding: 24px 0 0 0;

    .logo {
        text-align: center;
    }

    .dropdown.myAccount {
        .dropdown-menu.show {
            border: none;
            min-width: 11rem;
            top: 3.3rem;
        }

        a.dropdown-item {
            font-size: 14px;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
        }
    }

    .notification-dropdown {
        width: 330px !important;
        border: unset;
        border-radius: unset;
        right: 0 !important;

        &-title {
            background-color: #f6f6f6;
            text-transform: uppercase;
            font-weight: 600;
        }

        &-list {
            max-height: 65vh;
            min-height: 35vh;
            overflow-y: scroll;
        }
    }

    .notification-header {
        .amount-notification {
            color: #fff;
            font-size: 10px;
            top: 10px;
            right: 20px;
            position: absolute;
        }
    }

    .authentication {
        height: 35px;
        line-height: 35px;

        a {
            font-weight: 600;
            color: #6A6A6A;
        }
    }

    .button-custom {
        background: #f37021;
        width: 100px;
        margin-left: 15px;
        text-align: center;
        border-radius: 20px;

        a {
            color: #fff !important;
        }
    }

    .button-search {
        background: #006cd9;
        float: left;
        width: 10%;
        height: 55px;
        border: none;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;

        img {
            width: 25px;
        }
    }

    .input-search {
        float: left;
        width: 90%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        height: 55px;
        font-size: 14px;
    }

    .col-header-left, .col-header-right {
        padding-top: 10px;
    }

    .col-header-center {
        .navbar {
            padding-left: 0;

            .navbar-nav {
                .dropdown {
                    a.dropdown-toggle:after {
                        content: url("../images/icons/arrow-dropdown.svg") !important;
                        border: none !important;
                        vertical-align: inherit !important;
                    }
                }

                .nav-item {
                    position: relative;

                    &:first-child {
                        a {
                            padding-left: 0 !important;
                            padding-right: 15px;
                        }
                    }

                    &:last-child {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }
                }

                .nav-link {
                    color: #6A6A6A;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    position: relative;

                }
            }
        }
    }
}

.visible-mobile {
    display: none !important;
}

@media screen and (max-width: 1024px) {
    .h-85 .slick-list {
        max-height: 30vh !important;
    }
    .logo {
        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 820px) {
    .icon-map-header img {
        width: 20px;
        margin-top: 7px;
    }

    .visible-desktop {
        display: none !important;
    }
    .banner {
        .slick-slide {
            height: 30vh;
        }

        .visible-mobile {
            height: 100%;
        }
    }
    .visible-mobile {
        display: block !important;
        width: 100%;
        padding: 0 !important;
        object-fit: contain;
        //.navbar-expand-lg{
        //    left: 0;
        //    position: fixed;
        //    right: 0;
        //    z-index: 1030;
        //    top:0;
        //}
    }
}

@media screen and (max-width: 767px) {
    #header {
        .input-search {
            font-size: 12px;
            height: 40px;
            width: 85%;
        }

        .button-search {
            height: 40px;
            width: 15%;
            img{
                width: 20px;
            }
        }
    }
    .col-header-left {
        .logo {
            text-align: center;
        }
    }
    #header .authentication a {
        color: #fff;
    }
    .col-header-center {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .main-menu {
        .navbar {
            margin-top: -5px;
            margin-bottom: 15px;
        }
    }
    #header {
        .col-header-right {
            margin-top: -10px;
        }
    }
}

//===========================================
// -Header start
//===========================================

@media (max-width: 1442px) {
    #header {
        .navbar-nav {
            font-size: 92% !important;
        }

        #navbarSupportedContent {
            .font-95 {
                font-size: 92%;
            }

            ul {
                .btn-flexHome {
                    font-size: 100%;
                }
            }
        }


    }
}

@media (max-width: 1366px) {
    #header .authentication {
        line-height: 33px;
    }
    //header
    #header {
        .modal-map-header {
            span {
                font-size: 11px;
            }

            margin-left: 10px;
        }

        .col-header-left {
            img {
                width: 100%;
            }
        }

        #navbarSupportedContent {
            font-size: 96%;

            .btn-flexHome {
                font-size: 100%;
            }

            .nav-dropdown {
                .dropdown-menu {
                    a {
                        font-size: 96% !important;
                    }
                }
            }
        }

        .navbar-nav {
            .nav-link {
                font-size: 11px !important;
                padding-right: 10px !important;
                padding-left: 10px !important;
            }
        }

        .navbar-brand {
            margin-right: 0 !important;
        }

        .navbar-header button {
            display: none;
        }

        .font-95 {
            font-size: 94%;
        }

        .btn-flexHome {
            padding: 5px 30px;
        }

        .authentication {
            a {
                font-size: 11px;
            }
        }
    }
}

@media (max-width: 1280px) {
    //header
    #header .navbar-nav .nav-link {
        padding: 9px !important;
    }
    #navbarSupportedContent {
        font-size: 92% !important;

        .nav-dropdown {
            .dropdown-menu {
                a {
                    font-size: 92% !important;
                }
            }
        }
    }

    #header .navbar-nav {
        padding: 12px 0 9px 0 !important;
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1280px;
    }

    //header
    #header {
        font-size: 16px;
        font-family: $fontFamily;

        .navbar-nav {
            min-width: 68%;
            justify-content: space-evenly;
        }
    }
}

@media screen and (max-width: 1024px) {
    #header .navbar-nav .nav-link {
        font-size: 11px !important;
    }
    .navbar-brand {
        img {
            width: 80%;
        }
    }
    #header .btn-flexHome {
        padding: 5px 20px;
    }
    #header #navbarSupportedContent .font-95 {
        font-size: 75%;
    }
    #header .navbar-nav {
        font-size: 75% !important;
    }
}


@media (max-width: 768px) {
    .navbar-brand {
        margin-right: 0 !important;
    }

    .navbar-header {
        display: block !important;

        button {
            display: block !important;
        }
    }

    .navbar {
        padding: 0 !important;
    }

    #navbarSupportedContent {
        height: 100vh;
        transition: none !important;
    }
    #header nav {
        background-color: #fff;

        .navbar-brand {
            width: 50% !important;
            padding: 25px 15px;
        }

        .navbar-header {
            margin-right: 15px;
        }

        .logo-mobile {
            display: none;
        }

        .logo-full {
            max-width: 88%;
        }

        .nav-dropdown .dropdown-menu {
            background-color: #0054A6 !important;

            a.dropdown-item {
                color: white !important;
            }
        }

        .font-95 {
            font-size: 16px;
            color: white !important;

            a {
                color: white !important;
            }

            .btn-register {
                text-transform: none !important;
            }
        }
    }
    #header .navbar-nav {
        font-size: 100%;
        width: 100% !important;
        align-items: start !important;
        margin: 0 !important;
        //padding-bottom: 0 !important;
        padding: 0 !important;

        .nav-item {
            font-size: 16px;
            width: 100%;
            border-top: 1px solid #ccc;
            padding: 5px 15px 1px;

            &:after {
                height: 0 !important;
            }

            a.nav-link {
                text-transform: none;
                color: white !important;

                &:after {
                    height: 0 !important;
                }
            }
        }

        .font-95 a {
            color: white !important;
        }

        .btn-register {
            color: white !important;
        }

        .nav-dropdown {
            #navbarDropdown {
                text-transform: none !important;
            }

            .dropdown-menu {
                border: none !important;

                a.dropdown-item {
                    text-transform: none !important;
                    padding: 10px 25px;
                }

                .sub-active {
                    background: transparent !important;
                }
            }
        }
    }

    #header .navbar-nav li:last-child {
        width: 100%;
        text-align: center;

        a.text-light {
            font-size: 16px !important;
            padding: 6px 40px;
        }
    }
}

// Icon Responsive
.navbar-toggle {
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    height: 18px;
    width: 30px;
}

.dropdown-menu-3 {
    left: 20%;
    font-size: 1rem;
    list-style: none;
    margin: 0.125rem 0 0;
    min-width: 10rem;
    padding: 0.5rem 0;
    text-align: left;
    top: 100%;
    z-index: 1000;
}

#navbarDropdown3.dropdown-toggle:after {
    margin-left: 0.55em !important;
}

.navbar-header {
    margin-top: 5px;

    .navbar-toggle {
        .icon-bar {
            position: absolute;
            width: 100%;
            height: 3px;
            background: #fff;
            padding: 0;
            left: 0;
            top: 2px;

            &:first-child {
                position: absolute;
                width: 100%;
                left: 0;
                background: #fff;
                top: -4px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                -webkit-transform: rotate(-45deg) translate(-4px, 5px);
                transform: rotate(-45deg) translate(-4px, 5px);
            }

            &:last-child {
                position: absolute;
                width: 100%;
                left: 0;
                background: #fff;
                top: -4px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                top: 9px;
                -webkit-transform: rotate(45deg) translate(-4px, -5px);
                transform: rotate(45deg) translate(-4px, -5px);
            }

            &:nth-child(2) {
                background: transparent;
            }
        }
    }

    .navbar-toggle.collapsed {
        .icon-bar {
            &:nth-child(2) {
                background: #fff;
            }

            &:last-child {
                -webkit-transform: rotate(0deg) translate(0px, 0px);
                transform: rotate(0deg) translate(0px, 0px);
            }

            &:first-child {
                -webkit-transform: rotate(0deg) translate(0px, 0px);
                transform: rotate(0deg) translate(0px, 0px);
            }
        }
    }
}


// End Icon Responsive

#header {

    .notification-header {
        .dropdown-toggle:after {
            content: unset;
        }
    }

    .notification-header.dropdown {
        position: unset !important;
    }

    .notification-dropdown.dropdown-menu {
        left: unset !important;
    }

    .notification-dropdown {
        right: 0 !important;
        width: 301px;
        border: unset;
        border-radius: unset;
    }

    .notification-dropdown-list {
        overflow-y: scroll;
        min-height: 35vh;
        max-height: 65vh;
    }

    .notification-dropdown .btn-load-more {
        background-color: unset;
        color: #de7538;
        font-size: 15px;
        border: 1px solid #de7538;
        border-radius: 28px;
        padding: 7px 35px;
        text-decoration: none;
    }

    .notification-dropdown-title {
        background-color: #F6F6F6;
        text-transform: uppercase;
    }

    .notify-title-time {
        font-size: 10px;
        opacity: 0.6;
    }

    .notify-title-name {
        font-size: 13px;
    }

    .dropdown-item.notify-item {
        cursor: pointer !important;
    }

    .dropdown-item.notify-item:hover {
        transition: 0.4s;
        background: #ECF6FF !important;
        border: 1px solid #0054A6 !important;
    }

    .notify-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

    }

    .notify-desc-content {
        font-size: 12px;
        white-space: normal;
    }

    .navbar {
        background-color: #fff;

        .navbar-header {
            display: none;
        }

        .logo-mobile {
            display: none;
        }

        .dropdown-item {
            &:after {
                content: none !important;
            }
        }

        &-nav {
            padding: 12px 12px 9px 0;

            .nav-item {
                .nav-link {
                    color: #000;
                }

                &:after, .dropdown-item:after {
                    background: #f37021;
                    content: "";
                    display: block;
                    position: absolute;
                    height: 4px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    width: 80%;
                }

                &:hover {
                    &:after {
                        transition: -webkit-transform .3s;
                        transition: transform .3s;
                        transition: transform .3s, -webkit-transform .3s;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }

                &.active {
                    &:after {
                        transition: -webkit-transform .3s;
                        transition: transform .3s;
                        transition: transform .3s, -webkit-transform .3s;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }

            .nav-dropdown {
                #navbarDropdown {
                    text-transform: uppercase;
                }

                .dropdown-menu {
                    top: 55px;
                    left: -190px;
                    width: 300px;
                    border: none;
                    border-radius: 0;
                    padding: 0;

                    .sub-active {
                        background: #e9ecef;
                    }

                    .dropdown-item {
                        padding: 20px 25px;

                        &:active {
                            background-color: #e9ecef;
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .dropdown.myAccount {

        .type-account {
            font-size: 10px;
            margin-top: -0.5rem;
            margin-left: -1.1rem;
        }

        .dropdown-menu.show {
            min-width: 11rem;
            border: none;
            top: 3.3rem;
            border-radius: 0;
        }

        a.dropdown-item {
            padding-top: 0.5rem;
            font-size: 14px;
            padding-bottom: 0.5rem;
        }

    }

    .myAccount {
        .dropdown-item.active, .dropdown-item:active {
            background-color: #80808012 !important;
            color: #000 !important;
        }
    }
}

@media (max-width: 480px) {
    .icon-map-header img {
        width: 17px;
        margin-top: 0;
    }
}

//===========================================
// -Header end
//===========================================

