@import "../style.scss";
@import "../../vendors/slick-carousel/slick";
@import "../../vendors/slick-carousel/slick-theme";

body {
    font-family: $fontFamily;
}

.btn-prev {
    a:focus {
        box-shadow: none !important;
    }
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    background: #21252994;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.w-100 {
    width: 100%;
}

.banner {
    .slick-dotted.slick-slider {
        margin-bottom: 0 !important;
    }
}

.h-85 .slick-list {
    max-height: 53vh;
}

.exclusive-offer, .slider-blog {
    .slick-dots {
        bottom: -50px !important;
    }

    .slick-list {
        margin: 0 -10px;

        .slick-slide {
            margin-left: 10px;
            margin-right: 10px;
            text-align: center;

            img {
                object-fit: cover;
                width: 100%;
                border-radius: 10px;
            }
        }
    }
}

.slider-blog {
    .slick-slide {
        img {
            height: auto;
        }
    }
}

.exclusive-offer {
    .slick-slide {
        img {
            height: 165px;
        }
    }
}

.line-camp-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.line-camp-1 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.slider-kts {
    .slick-dots {
        bottom: 35px !important;
    }

    .slick-dots {
        //> li {
        //    display: inline-block;
        //}
        > li:only-child {
            display: none;
        }
    }
}

.slider-blog, .slider-exclusive-offer {
    .slick-dots {
        > li:only-child {
            display: none;
        }
    }
}

.slick-dots li button:before {
    color: #bcbcbc !important;
    font-size: 40px !important;
    opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
    color: #006CD9 !important;
}

.text-flex-home {
    color: #F37021;
    font-weight: 700;
    font-family: $fontFamilyBold;
}

.p-flex-home-80 {
    padding-bottom: 48px;
    padding-top: 48px;
}

.small-text {
    color: #00030E;
    margin-top: 15px;
    margin-bottom: 25px;
    font-weight: 400;
}


.design-experience {
    background-color: #F2F2F2;
}

.outstanding-works {
    .read-more {
        color: #6A6A6A;
        font-family: $fontFamilyBold;
        font-size: 16px;

        &:hover {
            color: #006CD9;
        }
    }

    .item-outstanding-works {
        position: relative;
        margin-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;

        .heart {
            position: absolute;
            z-index: 99;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }

        .block-thumbnail {
            img {
                border-radius: 15px;
                height: 300px;
                object-fit: cover;
            }
        }

        .block-information {
            position: absolute;
            bottom: 0px;
            color: #fff;
            width: 92%;
            padding: 20px 20px 0 20px;

            .name {
                font-size: 14px;
                font-family: $fontFamilySemiBold;
                line-height: 16px;
                font-weight: 600;
            }

            .other {
                margin-top: 10px;

                .other-left {
                    margin-right: 15px;

                    img {
                        border-radius: 100%;
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }
                }

                .other-right {
                    font-size: 11px;
                    font-weight: 600;
                }
            }

            .block-star {
                ul {
                    padding-left: 0 !important;

                    li {
                        list-style: none;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.design-inspiration {
    background-color: #F2F2F2;

    .slider-inspiration {
        width: 68%;
        margin: auto;

        .slick-list {
            .slider-item {
                height: 30px;
                text-align: center;
                width: auto;
                padding-right: 15px;
                padding-left: 15px;
                position: relative;

                span {
                    text-transform: uppercase;
                    font-family: $fontFamilyBold;
                    color: #6A6A6A;
                    font-weight: 700;
                    font-size: 15px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .slick-current {
                position: relative;

                span.choose {
                    color: #F37021 !important;

                    &:before {
                        background: #f37021;
                        content: "";
                        display: block;
                        height: 3px;
                        left: 0;
                        margin: auto;
                        right: 0;
                        position: absolute;
                        width: 70%;
                        bottom: 1px;
                    }
                }
            }

            .slick-active {
                span.choose {
                    color: #F37021 !important;

                    &:before {
                        background: #f37021;
                        content: "";
                        display: block;
                        height: 3px;
                        left: 0;
                        margin: auto;
                        right: 0;
                        position: absolute;
                        width: 70%;
                        bottom: 1px;
                    }
                }
            }
        }

        .slick-next, .slick-prev {
            top: 42% !important;
        }

        .slick-prev:before {
            content: url("/images/icons/arrow-slide-left.svg") !important;
        }

        .slick-next:before {
            content: url("/images/icons/arrow-slide-right.svg") !important;
        }
    }

    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        .grid-item, .step-content .grid-item {
            flex-direction: column;
            width: 24%;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;
        }

        .grid-item:nth-child(1) img,
        .grid-item:nth-child(3) img,
        .grid-item:nth-child(4) img,
        .grid-item:nth-child(5) img,
        .grid-item:nth-child(6) img,
        .grid-item:nth-child(7) img,
        .grid-item:nth-child(9) img,
        .grid-item:nth-child(10) img {
            height: 250px;
            object-fit: cover;
        }

        .grid-item:nth-child(2) img,
        .grid-item:nth-child(8) img {
            height: 520px;
            object-fit: cover;
        }

        .grid-item:nth-child(5) {
            position: absolute;
            top: 270px;
        }

        .grid-item:nth-child(8) {
            position: absolute;
            left: 48%;
            top: 270px;
        }

        .grid-item:nth-child(10) {
            position: absolute;
            top: 270px;
            left: 72%;
        }

        .grid-item:nth-child(9) {
            position: absolute;
            top: 540px;
            left: 72%;
        }
    }

    .grid-item {
        .thumbnail {
            img {
                border-radius: 5px;
            }
        }
    }

    .find-inspiration {
        background-color: #006cd9;
        border-radius: 20px;
        color: #fff;
        font-family: $fontFamilyBold;
        font-weight: 700;
        padding: 10px 15px;
        font-size: 15px;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }
    }
}

.blog {
    background-color: #F2F2F2;

    .slider-item {
        position: relative;

        .thumbnail {
            img {
                height: 250px;
                object-fit: cover;
            }
        }

        .information {
            position: absolute;
            padding: 15px;
            bottom: 0;
            left: 0;
            width: 100%;

            .category {
                color: #fff;
                font-size: 11px !important;
                margin-right: 30px;
                padding: 0;
                text-align: left;
                width: 85%;
            }

            .view_count {
                color: #fff;
                font-size: 11px;
                float: left;

                img {
                    width: 18px;
                    float: left;
                    object-fit: none;
                }
            }

            .date-time {
                color: #fff;
                font-size: 11px;
                width: 80%;
                float: left;
                text-align: left;
            }

            .name-post {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                margin-top: 10px !important;
                overflow: hidden;

                a {
                    color: #fff !important;
                }
            }
        }
    }
}

.pt-flex-home-55 {
    padding-top: 55px;
}

.how-it-works {
    .btn-read-more-step {
        background: #FDFDFD;
        border: 1px solid #C1C1C1;
        box-sizing: border-box;
        color: #6A6A6A;
        font-weight: 700;
        padding: 8px 16px;
        text-transform: uppercase;
        border-radius: 20px;

        &:hover {
            background: #A8A8A8;
            color: #fff;
        }

        &:active {
            background: #6A6A6A;
            color: #fff;
        }
    }

    &-banner {
        .banner-desktop {
            display: block;
        }

        .banner-mobile {
            display: none;
        }

        &-text {
            position: absolute;
            left: 50%;
            top: 82%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-family: $fontFamilyBold;
            font-size: 32px;
        }
    }

    &-breadcrumb {
        background-color: #f5f5f5;

        &-list {
            background-color: #f5f5f5;
            margin-bottom: 0;
            padding-bottom: 18px;
            padding-top: 18px;

            li a {
                color: $orange;
            }

            li .img_arrow_bread {
                padding: 0 20px;
            }
        }
    }

    .step-and-timeline {
        .step {
            .progress-container {
                display: flex;
                justify-content: space-between;
                max-width: 1150px;
                position: relative;
                margin: auto;

                &::before {
                    content: '';
                    background-color: #A8A8A8;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    height: 6px;
                    width: 100%;
                    z-index: -1;
                }

                .progress {
                    background-color: #0054a6;
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    transform: translateY(-50%); /* centers the line vertically along the y-axis */
                    height: 3px;
                    width: 0%;
                    z-index: -1;
                    transition: .4s ease;
                }

                .circle {
                    margin-top: 7px;
                    align-items: center;
                    background-color: #A8A8A8;
                    border-radius: 50%;
                    color: #fff;
                    display: -ms-flexbox;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    transition: .4s ease;
                    width: 30px;
                    padding: 22px;
                    font-size: 14px;
                    line-height: 30px;
                    cursor: pointer;

                    .step-text {
                        color: #656565;
                        font-size: 13px;
                        left: -50%;
                        position: absolute;
                        text-transform: uppercase;
                        top: 60px;
                        width: 210px;
                        font-weight: 600;
                        text-align: center;
                        transform: translateX(-30%);
                        line-height: 20px;
                        font-family: $fontFamilyBold;
                    }

                    &.active {
                        background-color: #006cd9;
                        border: 10px solid #80bfff;
                        color: #fff;
                        font-size: 14px;
                        padding: 22px;
                        margin-top: 0 !important;
                        margin-left: -3px;

                        .step-text {
                            color: #0054a6;
                        }
                    }

                }
            }

            .btn {
                background-color: #3498db;
                color: #fff;
                border: 0;
                border-radius: 6px;
                cursor: pointer;
                font-family: inherit;
                padding: 8px 30px;
                margin: 5px;
                font-size: 14px;
            }

            .btn:active {
                transform: scale(.98);
            }

            .btn:focus {
                outline: 0;
            }

            .btn:disabled {
                background-color: #e0e0e0;
                cursor: not-allowed;
            }
        }

        .step-description {
            .btn-step-block {
                margin-top: -7rem;
            }

            &-block-item {
                min-height: 500px;
            }

            &-title {
                color: #0054A6;
                font-family: $fontFamilyBold;
                font-weight: 700;
                font-size: 22px;
            }

            #prev {
                display: none;
            }

            .btn-previous {
                background-color: #fff;
                border: 1px solid $orange;
                color: $orange;
            }
        }

        .history-tl-container ul.tl {
            height: 200px;
            margin: 0;
            padding: 25px 25px 25px 0;

        }

        .history-tl-container ul.tl {
            li {
                border-left: 2px solid #A8A8A8;
                list-style: none;
                margin: auto;
                min-height: 60px;
                padding: 0 0 50px 25px;
                position: relative;
            }

            .item-title {
                position: absolute;
                top: -10px;

                a {
                    color: #0054A6;
                }
            }

            .tl-item-link {
                &:before {
                    top: 10px;
                }

                .item-title {
                    margin-top: 14px;
                }
            }
        }

        .history-tl-container ul.tl li:last-child {
            margin-left: 2px;
            border-left: 0;
        }

        .history-tl-container ul.tl li::before {
            background: #A8A8A8;
            border-radius: 50px;
            content: "";
            height: 10px;
            left: -6px;
            position: absolute;
            top: -5px;
            width: 10px;
        }

        .history-tl-container ul.tl li.active:before {
            content: url(/images/icons/dot.png);
            background: transparent;
            left: -8px;
        }
    }
}

.font-bold {
    font-family: $fontFamilyBold;
}

.brand {
    .slider-brand {
        img {
            width: 210px;
            height: 140px;
            object-fit: cover;
        }
    }
}

.btn-flexHome {
    background-color: #f37221;
    border: 1px solid #de7538;
    border-radius: 28px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    margin-top: 20px;
    padding: 7px 35px;
    text-decoration: none;
}

.btn-flexHome:hover {
    background-color: #f28d4e;
    color: #fff;
    text-decoration: none;
}

.position-relative {
    position: relative;
}

.img-overlay {
    background: linear-gradient(180deg, rgba(0, 3, 14, 0) 0%, rgba(0, 3, 14, 0.7) 100%);
    height: 65%;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 1366px) {
    .how-it-works {
        .step-and-timeline {
            .step {
                .progress-container {
                    max-width: 1100px;

                    .step-text {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .list-discovery-style {
        height: 1650px;
    }
    .grid-item, .step-content .grid-item {
        width: 50% !important;
    }
    .grid-item:nth-child(8) {
        left: 50% !important;
        top: 1080px !important;
    }
    .grid-item:nth-child(10) {
        left: 0 !important;
        top: 1080px !important;
    }
    .grid-item:nth-child(9) {
        left: 0 !important;
        top: 1350px !important;
    }
    .how-it-works {
        .step-and-timeline {
            .step {
                .progress-container {
                    .step-text {
                        left: 0 !important;
                        line-height: 2;
                        width: 110px !important;

                    }
                }
            }

            .step-description {
                &-block {
                    padding-top: 0;

                    .btn-step {
                        margin-top: 140px;
                    }
                }
            }

            .history-tl-container ul.tl {
                li {
                    min-height: 100px;
                }
            }
        }
    }
    .blog {
        .item-blog-category {
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width: 767px) {
    .banner {
        .slider-kts .slick-dots {
            bottom: 5px !important;
        }
    }
    .p-flex-home-80 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .list-discovery-style {
        height: auto !important;
    }
    .how-it-works {
        &-banner-text {
            font-size: 150%;
            font-family: $fontFamily;
            left: 50%;
            top: 70%;
        }

        &-breadcrumb {
            nav {
                background-color: #fff;

                ol.breadcrumb {
                    background-color: #fff;
                    padding-left: 0;
                }
            }
        }

        .banner-desktop {
            display: none;
        }

        .banner-mobile {
            display: block;
            width: 100%;
        }

        .step-and-timeline {
            padding-top: 50px;
            padding-bottom: 50px;
            background-color: #f0f1f5;

            &-big-text {
                text-align: center;
                font-size: 19px;
            }

            &-desc-text {
                text-align: center;
                margin-top: 15px;
                margin-bottom: 30px;
            }

            .step-description-block-item {
                min-height: 700px !important;
            }

            .step {
                .progress-container {
                    flex-direction: column;

                    &::before {
                        content: '';
                        background-color: #656565;
                        position: absolute;
                        left: -43%;
                        top: 48%;
                        transform: rotate(90deg);
                        height: 3px;
                        width: 99%;
                        z-index: 0;
                    }

                    .progress {
                        width: 3px !important;
                        left: 6%;
                        top: 35px;
                        transform: translateY(0);
                        z-index: 0;
                        height: 80% !important;
                    }

                    .circle {
                        margin-bottom: 25px;
                        margin-left: 3px;

                        .step-text {
                            left: 120px !important;
                            top: auto;
                            text-align: left;
                            width: 210px !important;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .step-description {
                padding-top: 0;

                .btn-step-block {
                    margin-top: -4rem;
                }

                &-block {
                    &-item {
                        .pt-flex-home-55 {
                            padding-top: 10px !important;
                        }
                    }
                }

                .btn-step {
                    justify-content: center !important;
                    margin-top: 150px;

                    .btn {
                        font-size: 100%;
                        padding: 10px;
                    }
                }

                .history-tl-container {
                    & ul.tl li {
                        min-height: 110px;
                    }
                }
            }
        }

        .bg-q-and-a {
            background-color: #fbfbfd;

            .q-and-a {
                padding-top: 50px;
                padding-bottom: 0;
                background-position: bottom;

                &-big-text {
                    font-size: 19px;
                    text-align: center;
                }

                &-desc-text {
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 30px;
                }
            }
        }

        .history-tl-container ul.tl li {

        }
    }
    .grid-item, .step-content .grid-item {
        width: 100% !important;
    }
    .design-inspiration .slider-inspiration .slick-list .slick-current a:before {
        width: 100px;
    }
    .how-it-works .step-and-timeline .step .progress-container .circle.active {
        margin-left: -8px;
    }
    .design-inspiration .grid .grid-item {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;

        img {
            height: auto !important;
        }
    }
}

.item-vr {
    background: #00030e;
    border-radius: 50%;
    height: 40px;
    left: 23px;
    line-height: 35px;
    opacity: .5;
    position: absolute;
    text-align: center;
    top: 17px;
    width: 40px;
    z-index: 1;
}

