//===========================================
// -Variables start
//===========================================

// Colors start
$black: #000;
$white: #fff;
$grey: #191919;
$light-grey: #383838;
$dark-blue: #21202f;
$bg-panel: #f4f4f4;
$btn-color: #686868;
$orange: #ff7e00;
$text-link: #9b9b9b;
$range-line: #a7a7a7;
$border-color: #e5e5e5;
$bg-icon: #949494;
// define your vars or override bootstrap vars
$default: red;
$primary: green;
$secondary: blue;
$alternate: cyan;

// Typography Family
$font-heading :	'utmavo', sans-serif !important;
$font-default: Verdana, sans-serif;
$font-primery: "Montserrat", sans-serif;
$font-bold: 'utm_avobold', sans-serif !important;

$font-path: "../fonts";

// define your Break Points or override bootstrap Break Points
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1366px,
  //xxxl: 1600px
);

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px
) ;

$default-duration: 0.5s;
$fast-duration: 0.25s;
$slow-duration: 1s;
$default-effect: ease-in-out;

// Z-Layer Variables
$z-layers: (
  "dropdown": 500,
  "modal": (
    "base": 100,
    "close": 101
  ),
  "scroll": 10,
  "header": 3,
  "nav-toogle": 4,
  "nav": 3,
  "upper": 2,
  "default": 1,
  "below": -1
);
// ===========================================
// -Variables end
// ===========================================


$fontFamily: 'Montserrat';
$fontFamilyMedium: 'Montserrat';
$fontFamilyBold: 'Montserrat';
$fontFamilySemiBold: 'Montserrat';

// version 2
$bgGrayDefault: #F2F2F2;
$bgBtnDefault: #006CD9;
$bgWhiteDefault: #FDFDFD;

$borderDefault: #C1C1C1;

$colorOrangeDefault: #F37021;
$colorTextDefault: #6A6A6A;
$colorPlaceholderDefault: #A8A8A8;
$colorDarkDefault: #00030E;
$colorWhiteDefault: #FFF;

$fontSize32: 32px;
$fontSize14: 14px;
$fontSize24: 24px;

$heightInput: 48px;
